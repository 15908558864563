import React, { useRef, useState } from "react";
import "./style.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primeflex/primeflex.css";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import logo from "./../../resources/logo-login.png";
import { sendResetCode } from "../../apis/SettingApis";
import { Toast } from "primereact/toast";
import { Spin } from "antd";

const SendResetCode = (props) => {
    const history = useHistory();
    const defaultValues = {
        username: ""
    };
    const { control, handleSubmit, reset } = useForm({ defaultValues });
    const toast = useRef(null);
    const [apiLoading, setApiLoading] = useState(false);

    const onSubmit = (data) => {
        handleSendResetCode(data);
        reset();
    };

    const handleSendResetCode = (data) => {
        setApiLoading(true);
        sendResetCode(data)
            .then((res) => {
                if (res.status === 200) {
                    toast.current.show({
                        severity: "success",
                        summary: "Send request reset password success, Please check your email.",
                        detail: "",
                        life: 5000,
                    });
                    setApiLoading(false);
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: res.response.data,
                        detail: res.response.data,
                        life: 3000,
                    });
                    setApiLoading(false);
                }
            })
            .catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
    }

    const handleBack = () => {
        history.push('/login'); // Navigating back to the login page
    };

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Spin spinning={apiLoading}>
                <div
                    className="flexgrid-demo p-dir-col custom-button-color"
                    style={{ margin: "0", width: "300" }}
                >
                    <div className="p-col">
                        <div className="box">
                            <div className="flex align-items-center justify-content-center">
                                <div
                                    className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
                                    style={{ width: "35%" }}
                                >
                                    <div className="text-center mb-2">
                                        <img
                                            src={logo}
                                            alt="logo"
                                            width={380}
                                            className="mt-5 w-100"
                                        />
                                    </div>

                                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                                        <div>
                                            <label
                                                htmlFor="username"
                                                className="block text-900 font-medium mb-2 custom-text-align"
                                            >
                                                Please enter your email address or username to search for your account.
                                            </label>
                                            <Controller
                                                name="username"
                                                control={control}
                                                rules={{ required: "Username/Email is required." }}
                                                render={({ field, fieldState }) => (
                                                    <InputText
                                                        id={field.name}
                                                        {...field}
                                                        autoFocus
                                                        className="w-full mb-3"
                                                        placeholder="Username or Email address"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <Button
                                                label="Back"
                                                icon="pi pi-angle-left"
                                                className="custom-button"
                                                style={{ backgroundColor: "lightgray" }}
                                                onClick={handleBack}
                                            />
                                            <Button
                                                type="submit"
                                                label="Reset"
                                                icon="pi pi-refresh"
                                                className="custom-button"
                                                style={{ marginLeft: 10 }}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </React.Fragment>
    );
};
export default SendResetCode;
