import { Form, Spin, Input, Select, Checkbox } from "antd";
import { Button as PrimeBtn } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";

import { updateProjectCapacity } from "../../../apis/SettingApis";

const UpdateProjectCapacityDialog = (props) => {
    const [apiLoading, setApiLoading] = useState(false);
    const toast = useRef(null);
    const [form] = Form.useForm();
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

    useEffect(() => {
        form.setFieldsValue({
            canHandleAutocad: props.user?.canHandleAutocad,
            ...daysOfWeek.reduce((acc, day) => {
                const lowerDay = day.toLowerCase();
                return {
                    ...acc,
                    [`${lowerDay}ContractorCapacity`]: props.user?.capacityDto?.[`${lowerDay}ContractorCapacity`],
                    [`${lowerDay}QcerCapacity`]: props.user?.capacityDto?.[`${lowerDay}QcerCapacity`],
                    [`${lowerDay}FinalCheckerCapacity`]: props.user?.capacityDto?.[`${lowerDay}FinalCheckerCapacity`],
                };
            }, {}),
        });
    }, [props]);

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            values.id = props.user?.id;

            const capacityDto = {
                mondayContractorCapacity: values.mondayContractorCapacity,
                mondayQcerCapacity: values.mondayQcerCapacity,
                mondayFinalCheckerCapacity: values.mondayFinalCheckerCapacity,
                tuesdayContractorCapacity: values.tuesdayContractorCapacity,
                tuesdayQcerCapacity: values.tuesdayQcerCapacity,
                tuesdayFinalCheckerCapacity: values.tuesdayFinalCheckerCapacity,
                wednesdayContractorCapacity: values.wednesdayContractorCapacity,
                wednesdayQcerCapacity: values.wednesdayQcerCapacity,
                wednesdayFinalCheckerCapacity: values.wednesdayFinalCheckerCapacity,
                thursdayContractorCapacity: values.thursdayContractorCapacity,
                thursdayQcerCapacity: values.thursdayQcerCapacity,
                thursdayFinalCheckerCapacity: values.thursdayFinalCheckerCapacity,
                fridayContractorCapacity: values.fridayContractorCapacity,
                fridayQcerCapacity: values.fridayQcerCapacity,
                fridayFinalCheckerCapacity: values.fridayFinalCheckerCapacity,
            };

            values.capacityDto = capacityDto;
            values.canHandleAutocad = values.canHandleAutocad == null ? false : values.canHandleAutocad;
            
            setApiLoading(true);
            updateProjectCapacity(values).then((res) => {
                if (res.status === 200) {
                    toast.current.show({
                        severity: "success",
                        summary: "Status",
                        detail: "Update User successfully",
                        life: 3000
                    });
                    props.setVisible(false);
                } else {
                    toast.current.show({
                        severity: "warn",
                        summary: "Status",
                        detail: "Update User failed",
                        life: 3000
                    });
                    props.setVisible(false);
                }
                setApiLoading(false);
            }).catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
        });
    };

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Dialog
                style={{ width: "660px" }}
                header={"Update Project Capacity: " + props.user?.username}
                visible={props.visible}
                onHide={() => { props.setVisible(false); }}
            >
                <Spin spinning={apiLoading}>
                    <div className='flex justify-content-between align-items-center'>
                        <Form form={form} onFinish={handleSubmit}>
                            <Form.Item
                                required={true}
                                name="canHandleAutocad"
                                label="Can Handle Autocad"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                                valuePropName="checked"
                            >
                                <Checkbox onKeyDown={(e) => e.stopPropagation()} />
                            </Form.Item>

                            {/* Dynamic capacities for each day */}
                            {daysOfWeek.map((day) => {
                                const lowerDay = day.toLowerCase();
                                return (
                                    <div key={day}>
                                        <h4>{day}</h4>
                                        <div style={{ display: "flex", gap: "20px" }}>
                                            <Form.Item
                                                required={true}
                                                name={`${lowerDay}ContractorCapacity`}
                                                label={`Contractor`}
                                                labelCol={{ span: 12 }}
                                                labelAlign="left"
                                            >
                                                <Input
                                                    style={{ width: "100px" }}
                                                    onKeyDown={(e) => e.stopPropagation()}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                required={true}
                                                name={`${lowerDay}QcerCapacity`}
                                                label={`QCer`}
                                                labelCol={{ span: 12 }}
                                                labelAlign="right"
                                            >
                                                <Input
                                                    style={{ width: "100px" }}
                                                    onKeyDown={(e) => e.stopPropagation()}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                required={true}
                                                name={`${lowerDay}FinalCheckerCapacity`}
                                                label={`Final Checker`}
                                                labelCol={{ span: 14 }}
                                                labelAlign="right"
                                            >
                                                <Input
                                                    style={{ width: "100px" }}
                                                    onKeyDown={(e) => e.stopPropagation()}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                );
                            })}

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <PrimeBtn
                                    style={{
                                        backgroundColor: "#037eea",
                                        borderColor: "#037eea",
                                        float: "right",
                                        marginRight: "1rem",
                                    }}
                                    type="submit"
                                    label="Submit"
                                />
                            </div>
                        </Form>
                    </div>
                </Spin>
            </Dialog>
        </React.Fragment>
    );
};

export default UpdateProjectCapacityDialog;
