import React from "react";
import "./style.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import "primeflex/primeflex.css";
import { useDispatch } from "react-redux";
import { loginUser } from "./../../actions/user";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import logo from "./../../resources/logo-login.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SendResetCode from "./SendResetCode";
import ResetPassword from "./ResetPassword";

const LoginForm = (props) => {
  document.documentElement.style.fontSize = "13px";

  const dispatch = new useDispatch();

  const history = useHistory();
  const location = useLocation();

  const defaultValues = {
    username: "",
    password: "",
  };

  const { control, handleSubmit, reset } = useForm({ defaultValues });

  function handleLogin(values) {
    dispatch(loginUser(values, history, location, props));
  }

  const onSubmit = (data) => {
    handleLogin(data);
    reset();
  };

  const handleRequestResetPass = () => {
    history.push('/sendResetCode');
  }

  const passwordHeader = <h6>Pick a password</h6>;

  return (
    <React.Fragment>
      {location.pathname === "/sendResetCode" ? (
        <SendResetCode />
      ) : location.pathname === "/resetPassword" ? ( 
        <ResetPassword /> 
      ) :
        (<div
          className="flexgrid-demo p-dir-col custom-button-color"
          style={{ margin: "0" }}
        >
          <div className="p-col">
            <div className="box">
              <div className="flex align-items-center justify-content-center">
                <div
                  className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
                  style={{ width: "35%" }}
                >
                  <div className="text-center mb-2">
                    <img
                      src={logo}
                      alt="logo"
                      width={380}
                      // style={{ mar }}
                      className="mt-5 w-100"
                    />
                    <span className="text-600 font-medium line-height-3">
                      {/* Don't have an account? */}
                    </span>
                    <a
                      href
                      className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
                    // onClick={registerBtn}
                    >
                      {/* Create Account! */}
                    </a>
                  </div>

                  <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                    <div>
                      <label
                        htmlFor="username"
                        className="block text-900 font-medium mb-2 custom-text-align"
                      >
                        Username*
                      </label>
                      <Controller
                        name="username"
                        control={control}
                        rules={{ required: "Username is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoFocus
                            className="w-full mb-3"
                          />
                        )}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-900 font-medium mb-2 custom-text-align"
                      >
                        Password*
                      </label>
                      <Controller
                        name="password"
                        control={control}
                        rules={{ required: "Password is required." }}
                        render={({ field, fieldState }) => (
                          <div className="custom-password-field">
                            <Password
                              id={field.name}
                              {...field}
                              className="w-full mb-3"
                              toggleMask
                              header={passwordHeader}
                              feedback={false}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <Link to ="/sendResetCode" style={{fontSize:"small"}}> Fogotten Password?</Link>
                    </div>
                    <div>
                      <Button
                        type="submit"
                        label="Login"
                        icon="pi pi-user"
                        className="custom-button"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>)}
    </React.Fragment>
  );
};
export default LoginForm;
