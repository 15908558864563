import React, { useRef, useState } from "react";
import "./style.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primeflex/primeflex.css";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import logo from "./../../resources/logo-login.png";
import { sendResetPassword } from "../../apis/SettingApis";
import { Toast } from "primereact/toast";
import { Spin } from "antd";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Password } from "primereact/password";
import { message } from "antd";

const ResetPassword = (props) => {
    const history = useHistory();
    const defaultValues = {
        password: "",
        confirmPassword: ""
    };
    const { control, handleSubmit, reset } = useForm({ defaultValues });
    const toast = useRef(null);
    const [apiLoading, setApiLoading] = useState(false);
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const resetCode = queryParams.get("resetCode");

    const onSubmit = (data) => {
        handleResetPassword(data);
        reset();
    };

    const handleResetPassword = (data) => {
        if (data.password !== data.confirmPassword) {
            toast.current.show({
                severity: "error",
                summary: "Please enter password same with confirm password",
                detail: "",
                life: 3000,
            });
            reset();
            return;
        }
        data.resetCode = resetCode;
        setApiLoading(true);
        sendResetPassword(data)
            .then((res) => {
                if (res.status === 200) {
                    message.success("Reset password success");
                    history.push("/login")
                    setApiLoading(false);
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: res.response.data,
                        detail: res.response.data,
                        life: 3000,
                    });
                    setApiLoading(false);
                }
            })
            .catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
    }

    const handleBack = () => {
        history.push('/login'); // Navigating back to the login page
    };

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Spin spinning={apiLoading}>
                <div
                    className="flexgrid-demo p-dir-col custom-button-color"
                    style={{ margin: "0", width: "300" }}
                >
                    <div className="p-col">
                        <div className="box">
                            <div className="flex align-items-center justify-content-center">
                                <div
                                    className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
                                    style={{ width: "35%" }}
                                >
                                    <div className="text-center mb-2">
                                        <img
                                            src={logo}
                                            alt="logo"
                                            width={380}
                                            className="mt-5 w-100"
                                        />
                                    </div>

                                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                                        <div>
                                            <label
                                                htmlFor="password"
                                                className="block text-900 font-large mb-2 custom-text-align"
                                            >
                                                Please enter your new password below: 
                                            </label>
                                            <Controller
                                                name="password"
                                                control={control}
                                                rules={{ required: "Password is required." }}
                                                render={({ field, fieldState }) => (
                                                    <div className="custom-password-field">
                                                        <Password
                                                            id={field.name}
                                                            {...field}
                                                            className="w-full mb-3"
                                                            toggleMask
                                                            feedback={false}
                                                            placeholder="Password"
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="confirmPassword"
                                                control={control}
                                                rules={{ required: "Password is required." }}
                                                render={({ field, fieldState }) => (
                                                    <div className="custom-password-field">
                                                        <Password
                                                            id={field.name}
                                                            {...field}
                                                            className="w-full mb-3"
                                                            toggleMask
                                                            feedback={false}
                                                            placeholder="Confirm Password"
                                                        />
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <Button
                                                label="Login"
                                                icon="pi pi-angle-left"
                                                className="custom-button"
                                                style={{ backgroundColor: "lightgray" }}
                                                onClick={handleBack}
                                            />
                                            <Button
                                                type="submit"
                                                label="Reset"
                                                icon="pi pi-refresh"
                                                className="custom-button"
                                                style={{ marginLeft: 10 }}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </React.Fragment>
    );
};
export default ResetPassword;
