import {Form, Radio, Select, Spin} from "antd";
import {Button as PrimeBtn} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import React, {useEffect, useRef, useState} from "react";
import { confirmDialog } from "primereact/confirmdialog";

import {
    deliveredToAdjustmentRequired,
    acceptedProject,
    updateAdjustmentData
  } from "../../../apis/ProjectApis";

const CustomerRequestChangeDialog = (props) => {
    const [apiLoading, setApiLoading] = useState(false);
    const toast = useRef(null);
    const {Option} = Select;
    const [form] = Form.useForm();

    const handleSubmit = () => {
        const customerAcceptProject = (record, isAssignToMe) => {
            if (record && record.id) {
              setApiLoading(true);
              acceptedProject(record.id, isAssignToMe)
                .then((res) => {
                  if (res.status === 200) {
                    toast.current.show({
                      severity: "success",
                      summary: "Status",
                      detail: "Project Status Change To Accepted Successfully",
                      life: 3000,
                    });
                    setApiLoading(false);
                  } else {
                    toast.current.show({
                      severity: "error",
                      summary: "Status",
                      detail: res.response.data,
                      life: 3000,
                    });
                    setApiLoading(false);
                  }
                  props.setVisible(false);
                })
                .catch((e) => {
                  toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                  });
                  setApiLoading(false);
                });
            } else {
              toast.current.show({
                severity: "warn",
                summary: "Status",
                detail: "No Selected Project",
                life: 3000,
              });
            }
          };
        
        form.validateFields().then((values) => {
            setApiLoading(true);
            const projectValues = {
                id: props.project.id,
                adjustment: values.adjustment,
                adjustmentMadeBy: values.adjustmentMadeBy,

            };

            
            if (props.project.projectStatus === "FINAL_CHECK") {
                updateAdjustmentData(projectValues)
                .then((res) => {
                    if (res.status === 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "Status",
                            detail: "Update adjustment data Successfully",
                            life: 3000,
                        });
                        setApiLoading(false);
                        props.setVisible(false);

                        //show confirm to move dialog
                        if(props.project.manager == null){
                            confirmDialog({
                              message: "Do you want to assign yourself to Project Final Checker and status Accept?",
                              header: "Confirmation",
                              icon: "pi pi-exclamation-triangle",
                              accept: () => customerAcceptProject(props.project, true),
                              reject: () => customerAcceptProject(props.project, false),
                            });
                          } else {
                            confirmDialog({
                              message: "Are you sure you want to change status to Accept?",
                              header: "Confirmation",
                              icon: "pi pi-exclamation-triangle",
                              accept: () => customerAcceptProject(props.project, false),
                            });
                          }
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Status",
                            detail: res.response.data,
                            life: 3000,
                        });
                        setApiLoading(false);
                    }
                })
                .catch((e) => {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: e,
                        life: 3000,
                    });
                    setApiLoading(false);
                });
        
        } else {
                deliveredToAdjustmentRequired(projectValues)
                    .then((res) => {
                        if (res.status === 200) {
                            console.log(JSON.stringify(res))
                            props.setRedirectToProjectId(res.data.id);
                            toast.current.show({
                                severity: "success",
                                summary: "Status",
                                detail: "Project Status Change To Make Changes Successfully",
                                life: 3000,
                            });
                            setApiLoading(false);
                            props.setVisible(false);
                        } else {
                            toast.current.show({
                                severity: "error",
                                summary: "Status",
                                detail: res.response.data,
                                life: 3000,
                            });
                            setApiLoading(false);
                        }
                    })
                    .catch((e) => {
                        toast.current.show({
                            severity: "error",
                            summary: "Status",
                            detail: e,
                            life: 3000,
                        });
                        setApiLoading(false);
                    });
            }


        });
    };

    useEffect(() => {
        if (props?.project) {
            form.setFieldsValue({adjustment: props.project.adjustment});
            form.setFieldsValue({adjustmentMadeBy: props.project.adjustmentMadeBy});
        }
    }, [props.project]);

    const validateAdjustment = (_, value) => {
        if (value === null || value === "None") {
            return Promise.reject('Please select an Adjustment option');
        }
        return Promise.resolve();
    };
    return (
        <React.Fragment>
            <div>
                <Toast ref={toast}/>
                <Dialog
                    header="Customer Request Change"
                    visible={props.visible}
                    onHide={() => props.setVisible(false)}>
                    <Spin spinning={apiLoading}>
                        <React.Fragment>
                            <div className="flex justify-content-between align-items-center">
                                <div>
                                    <Form form={form}
                                          onFinish={handleSubmit}
                                    >
                                        <Form.Item name="adjustment" label="Adjustment" rules={[
                                            {
                                                required: true,
                                                validator: validateAdjustment,
                                            },
                                        ]}>
                                            <Radio.Group defaultValue="None">
                                                <Radio value="Small">Small</Radio>
                                                <Radio value="Big">Big</Radio>
                                                <Radio value="Remake">Remake</Radio>
                                                <Radio value="Without Cost">Without Cost</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        {/* <Form.Item name="adjustmentMadeBy" label="Adjustment Made By"
                                                   rules={[
                                                       {
                                                           required: true,
                                                           message: 'Please select who made the adjustment!',
                                                       },
                                                   ]}>
                                            <Select
                                                style={{width: 150}}
                                                placeholder="Select"
                                                dropdownStyle={{zIndex: "9999"}}>
                                                <Option value="Manager">Manager</Option>
                                                <Option value="Quality Checker">Quality Checker</Option>
                                                <Option value="Contractor">Contractor</Option>
                                            </Select>
                                        </Form.Item> */}
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <PrimeBtn
                                                style={{
                                                    backgroundColor: "#037eea",
                                                    borderColor: "#037eea",
                                                    float: "right",
                                                    marginRight: "1rem",
                                                }}
                                                type="submit"
                                                label="Submit"
                                            />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </React.Fragment>
                    </Spin>
                </Dialog>
            </div>
        </React.Fragment>
    );
};

export default CustomerRequestChangeDialog;

