import { Spin } from "antd";
import { Button } from "primereact/button";
import { confirmPopup } from "primereact/confirmpopup";
import { Dialog } from "primereact/dialog";
import { ListBox } from "primereact/listbox";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { reAssignProjectCoodinator } from "../../../apis/ProjectApis";
import { getUsersByRoles } from "../../../apis/SettingApis";
import { USER_TYPE_MANAGER } from "../../../utils/constants";
import "./style.scss"

const AssignToManagerDialog = (props) => {
  const [apiLoading, setApiLoading] = useState(false);
  const [allEligibleUsers, setAllEligibleUsers] = useState([]);
  const toast = useRef(null);

  const fetchUsers = () => {
    setApiLoading(true);
    getUsersByRoles(USER_TYPE_MANAGER, props.project?.id)
      .then((res) => {
        if (res.status === 200) {
          res.data.unshift({
            id: "",
            numProjectCanAssign: "Assignable Today"
          });
          setAllEligibleUsers(res.data);
          setApiLoading(false);
          console.log(JSON.stringify(res.data));
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  };

  useEffect(() => {
    if (props.visible) {
      fetchUsers();
    }
  }, [props.visible]);

  const handleReAssignProjectCoodinator = (record) => {
    setApiLoading(true);
    let newValues = {
      projectId: props.project.id,
      userId: record.id,
    };
    reAssignProjectCoodinator(newValues)
      .then((res) => {
        if (res.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Status",
            detail: `Project Assign To ${record.name} Successfully`,
            life: 3000,
          });
          setApiLoading(false);
          props.setVisible(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div>
        <Toast ref={toast} />
        <Dialog
          header="Assign Project Final Checker"
          visible={props.visible}
          onHide={() => props.setVisible(false)}
        >
          <Spin spinning={apiLoading}>
            <ListBox
              options={allEligibleUsers}
              listClassName="overflow-y-auto"
              listStyle={{ height: "50vh", whiteSpace: "nowrap", overflowX: "auto", width: "100%", display: "flex"  }}  // Prevent text wrapping              listStyle={{ height: "50vh" }}
              filter
              optionLabel={(e) => `${e.name} ${e.username}`}
              itemTemplate={(user) => {
                return (
                  <React.Fragment>
                    <div className="flex justify-content-between align-items-center">
                      <div>
                        <h5 className="m-0 w-10rem">{user.name}</h5>
                      </div>
                      <div style={{ width: "18rem", textAlign: "center" }}>
                        <h5 className="m-0 w-10rem">{user.numProjectCanAssign}</h5>
                      </div>
                      <div style={{ width: "18rem"}}>
                        {user.roles != null ? (<Tag
                          className="bg-gray-400"
                          severity="success"
                          value={
                            <h5 className="m-0 text-black-alpha-90">
                              {user.roles[0].name}
                            </h5>
                          }
                        />) :
                          (<h5 className="m-0 text-black-alpha-90">
                            Role
                          </h5>)}
                      </div>
                      <div style={{ width: "8rem" }}>
                        {user.id != "" ? (<Button
                          style={{ backgroundColor: "#037eea", zIndex: 9999 }}
                          label="Assign"
                          disabled={
                            props.project?.manager?.id === user.id
                              ? true
                              : false
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            confirmPopup({
                              target: e.currentTarget,
                              message: "Are you sure you want to proceed?",
                              icon: "pi pi-exclamation-triangle",
                              accept: () =>
                                handleReAssignProjectCoodinator(user),
                            });
                          }}
                        />) :("")}
                      </div>
                    </div>
                  </React.Fragment>
                );
              }}
            />
          </Spin>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default AssignToManagerDialog;
